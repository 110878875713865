import React, { useState } from "react";
import "./AddEditPlan.css";
const ViewSubs = ({ data }) => {
  const [isActive, setIsActive] = useState(data?.is_active);

  // const toggleState = () => {
  //   setIsActive((prevState) => !prevState);
  // };

  return (
    <div className="view-sub-page">
      <div className="Plan-name">Subscription Plan Name</div>
      <span className="pln-desc">{data?.plan_name || "N/A"}</span>
      <div className="Plan-name">Original Price</div>
      <span className="pln-desc">
        {data?.original_price ? `$${data?.original_price}` || "N/A" : "N/A"}
      </span>
      <div className="Plan-name">Discounted Price</div>
      <span className="pln-desc">
        {data?.discounted_price ? `$${data?.discounted_price}` || "N/A" : "N/A"}
      </span>
      <div className="Plan-name">Features</div>
      <div className="pln-desc" style={{wordWrap:'break-word' }}>
        {data?.features || "N/A"}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "start",
          marginTop: "25px",
          cursor: "none",
        }}
      >
        <div
          className={`toggle-switch ${isActive ? "active" : ""}`}
          // onClick={toggleState}
          style={{ cursor: "default" }}
        >
          <div className="toggle-knob"></div>
        </div>
        <span>{isActive ? "Active" : "Inactive"}</span>
      </div>
    </div>
  );
};

export default ViewSubs;
