import React, { useState, useRef, useEffect } from "react";
import RulesRow from "../../common/tablerow/RulesRow";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import { Button, TableCell, TableRow } from "@mui/material";
import TableComp from "../../common/tablecomp/TableComp";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import RulesModal from "../../common/modal/RulesModal";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import FoodRow from "../../common/tablerow/FoodRow";
import UploadIcon from '../../common/assest/solar_upload-bold.png';
import { useSelector } from "react-redux";
import { addNewFoodApi, deleteFoodApi, getFoodApi, updateFoodApi } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import alertDialog from "../../utils/alertDialog";

const FoodSpecialty = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [foodData, setFoodData] = useState([]);
  const [change, setChange] = useState(false);
  const [myTimout, setMyTimout] = useState();
  const [inputTxt, setInputTxt] = useState("");
  const [editRule, setEditRule] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [resetting, setResetting] = useState(false);
  const fileInputRef = useRef(null);

  // Fetch food specialties data
  const handleFood = async () => {
    try {
      if(!resetting) setLoading(true);
      const response = await getFoodApi({ token, searchQuery });
      setFoodData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    handleFood();
  }, [change]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      handleFood();
    } else {
      if (myTimout) clearTimeout(myTimout);
      setMyTimout(setTimeout(handleFood, 500));
    }
  }, [searchQuery]);

  // Handle creating a new food specialty
  const handleCreateFood = async () => {
    if (!inputTxt || !selectedImage) {
      toast.warning("Please fill the food specialty name and upload an image");
      return;
    }
    try {
      const payload={
        name:inputTxt,
        image:selectedImage.file
      }
      const response = await addNewFoodApi({ token,payload });
      toast.success("New Food Specialty Added successfully");
      setChange(!change);
      setOpen(false);
      setInputTxt("");
      setSelectedImage(null);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  // Handle editing an existing food specialty
  const handleEditFood = async () => {
    if (!editRule || !inputTxt || !selectedImage) {
      toast.warning("Please fill the food specialty name and upload an image");
      return;
    }
    try {
      const updateFoodSpecialty = { ...editRule, name: inputTxt, image: selectedImage.file };
      const response = await updateFoodApi({ token, updateFoodSpecialty });
      toast.success("Food Specialty Updated successfully");
      setChange(!change);
      setOpen(false);
      setInputTxt("");
      setSelectedImage(null);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleDeleteFood = async (id) => {
    const confirmed = await alertDialog({
      title: "Confirm Action",
      description: "Are you sure you want to Delete this Food Specialty?",
    });
    if (!confirmed) {
      return;
    }
    try {
      const response = await deleteFoodApi({ token, id });
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage({ file, imageURL });
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const styles = {
    width: "275px",
  };

  const titles = [
    { title: "Sr No" },
    { title: "Food Specialty Image" },
    { title: "Food Specialty Name" },
    { title: "Action" },
  ];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Food Speciality </span>,
  ];

  const handleSearchChange = (newValue) => {
    setSearchQuery(newValue);
  };

  const resetPage = () => {
    setResetting(true);
    // setCurrentPage(1);
  };


  const handleCreateRule = () => {
    setEditRule(null);
    setInputTxt("");
    setOpen(true);
  };

  const openEditModal = (rule) => {
    setEditRule(rule);
    setInputTxt(rule?.name || "");
    setSelectedImage({ imageURL: rule?.image || "" });
    setOpen(true);
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="whole-card-wrapper-notification">
        <TableCard>
          <div className="push-notification-txt">Food Speciality </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <div>
            <SearchBar onSearchChange={handleSearchChange} searchValue={searchQuery}
            placeholder="Search by name" style={styles} resetPage={resetPage}/>
            </div>

            <div>
              <Button
                onClick={handleCreateRule}
                sx={{ width: "220px" }}
                variant="contained"
                className="create-admin-btn"
              >
                Create New Specialty
              </Button>
            </div>
          </div>

          <TableComp title={titles}>
            {foodData.length > 0 ? (
              foodData.map((item, idx) => (
                <FoodRow
                  key={item?._id}
                  index={idx}
                  row={item}
                  edit={() => openEditModal(item)}
                  deleted={() => handleDeleteFood(item?._id)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={titles.length} align="center" sx={{ fontFamily: "Rosario", fontWeight: "600" }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableComp>
        </TableCard>
      </div>

      <RulesModal open={open} handleClose={handleClose}>
        <div className="create-modal-heading">
          <div>{editRule ? "Edit Food Specialty" :"Create New Food Specialty "}</div>
          <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {selectedImage ? (
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={selectedImage.imageURL}
                alt="Selected"
                style={{ width: "140px", height: "80px", borderRadius: "10px" }}
              />
              <ClearIcon
                onClick={handleRemoveImage}
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  cursor: "pointer",
                  backgroundColor: "#EA1D26",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImageSelect}
                accept="image/*"
              />
              <img
                src={UploadIcon}
                style={{ width: "40px", cursor: "pointer" }}
                onClick={() => fileInputRef.current.click()}
                alt="Upload Icon"
              />
              <div
                style={{
                  fontFamily: "Rosario",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => fileInputRef.current.click()}
              >
                Upload Image
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <textarea
            placeholder="Enter here"
            value={inputTxt}
            onChange={(e) =>
              e.target.value.trim() !== "" || e.target.value === "" ? setInputTxt(e.target.value) : null
            }
            rows={3}
            cols={89}
            style={{
              width: "90%",
              border: "none",
              backgroundColor: "#F0F0F0",
              borderRadius: "25px",
              resize: "none",
              outline: "none",
              textIndent: "20px",
              paddingTop: "20px",
              fontWeight: "400",
              color: "#120B1C",
              fontFamily: "Rosario",
            }}
          />
        </div>

        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <CustomBtn1 text={editRule ? "Update" : "Add"} onClick={editRule ? handleEditFood : handleCreateFood} />
          </div>
        </div>
      </RulesModal>
    </div>
  );
};

export default FoodSpecialty;
