import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar, Menu, MenuItem } from "@mui/material";
import ViewIcon from '../assest/SubViewIcon.svg'
import DeleteIcon from '../assest/SubDeleteIcon.svg'
import EditIcon from '../assest/SubEditIcon.svg'

// import { useNavigate } from "react-router-dom";

const SubscriptionPlanRow = ({row,idx,view,edit,deletePlan}) => {


 

  return (
    <>
    
        <TableRow >     

             <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{idx+1}</div>
          </TableCell> 
          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row?.plan_name||"N/A"}</div>
          </TableCell>


       
        

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row?.discounted_price ? `$${row?.discounted_price}`:"N/A"}</div>
          </TableCell>
          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px",
              color: row?.is_active ? "#009048" : "#EA1D26",
            }}
          >
            <div>  { row?.is_active ? "Active" : "Inactive" }</div>
          </TableCell>


          <TableCell sx={{display:'flex',gap:'10px',justifyContent:"end"}}> 
                <img src={ViewIcon} style={{cursor:'pointer'}} onClick={view}/>
                <img src={EditIcon} style={{cursor:'pointer'}}  onClick={edit} />
                <img src={DeleteIcon} style={{cursor:'pointer'}} onClick={deletePlan}/>
          </TableCell>
          
        

        </TableRow>

        
    
    </>
  );
};

export default SubscriptionPlanRow;
