import React, { useEffect, useState } from 'react';
import "./AddEditPlan.css";
import CustomBtn from "../custombtn/CustomBtn";
import { Box, TextField, Typography } from "@mui/material";
import { toast } from 'react-toastify';

const AddEditPlan = ({ onSave,editedData }) => {


  // console.log("edited data",editedData)

  const [is_active, setIsActive] = useState(editedData?.is_active || false);
  const [formData, setFormData] = useState({
    plan_name: '',
    original_price: '',
    discounted_price: '',
    features: '',
  });
 
  useEffect(() => {
    if (editedData) {
      setFormData({
        plan_name: editedData.plan_name || '',
        original_price: editedData.original_price || '',
        discounted_price: editedData.discounted_price || '',
        features: editedData.features || '',
        id:editedData?._id
      });
      setIsActive(editedData.is_active || false);
    }
  }, [editedData]);

  const toggleState = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
    setFormData({ ...formData, [name]: value });
    }
  };


  const handleSave = () => {
    const { plan_name, original_price, discounted_price, features } = formData;
    if (!plan_name || !original_price || !discounted_price || !features) {
      toast.warning('Please fill all required fields!');
      return;
    }
    const payload = { ...formData, is_active };
    onSave(payload);
  };

  const style = {
    background: 'White',
    color: '#000',
    border: '1px solid #EAEAEA',
  };

  return (
    <div>
      <div className="add-card-sub">
        {/* Plan Name */}
        <Box sx={{ marginTop: '25px', width: '100%' }}>
          <Typography
            sx={{
              marginBottom: '8px',
              fontSize: '17px',
              fontWeight: '600',
              textIndent: '10px',
              fontFamily: 'Rosario',
            }}
          >
            Subscription Plan Name
          </Typography>
          <TextField
            type="text"
            placeholder="Enter here"
            name="plan_name"
            value={formData.plan_name}
            onChange={handleInputChange}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              style: {
                borderRadius: '28px',
                backgroundColor: '#F0F0F0',
                fontSize: '14px',
                fontFamily: 'Rosario',
              },
            }}
          />
        </Box>

        {/* Original Price */}
        <Box sx={{ marginTop: '25px', width: '100%' }}>
          <Typography
            sx={{
              marginBottom: '8px',
              fontSize: '17px',
              fontWeight: '600',
              textIndent: '10px',
              fontFamily: 'Rosario',
            }}
          >
            Original Price
          </Typography>
          <TextField
            type="number"
            placeholder="Enter here"
            name="original_price"
            value={formData.original_price}
            onChange={handleInputChange}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              style: {
                borderRadius: '28px',
                backgroundColor: '#F0F0F0',
                fontSize: '14px',
                fontFamily: 'Rosario',
              },
            }}
          />
        </Box>

        {/* Discounted Price */}
        <Box sx={{ marginTop: '25px', width: '100%' }}>
          <Typography
            sx={{
              marginBottom: '8px',
              fontSize: '17px',
              fontWeight: '600',
              textIndent: '10px',
              fontFamily: 'Rosario',
            }}
          >
            Discounted Price
          </Typography>
          <TextField
            type="number"
            placeholder="Enter here"
            name="discounted_price"
            value={formData.discounted_price}
            onChange={handleInputChange}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              style: {
                borderRadius: '28px',
                backgroundColor: '#F0F0F0',
                fontSize: '14px',
                fontFamily: 'Rosario',
              },
            }}
          />
        </Box>

       
        <Box sx={{ marginTop: '25px', width: '100%' }}>
          <Typography
            sx={{
              marginBottom: '8px',
              fontSize: '17px',
              fontWeight: '600',
              textIndent: '10px',
              fontFamily: 'Rosario',
            }}
          >
            Features
          </Typography>
          <textarea
            placeholder="Enter here"
            rows={3}
            name="features"
            value={formData.features}
            onChange={handleInputChange}
            style={{
              width: '100%',
              border: 'none',
              backgroundColor: '#F0F0F0',
              borderRadius: '25px',
              resize: 'none',
              outline: 'none',
              textIndent: '20px',
              paddingTop: '20px',
              fontWeight: '400',
              color: '#120B1C',
              fontFamily: 'Rosario',
              height: '120px',
            }}
          />
        </Box>

       
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center', marginTop: '25px' }}
        >
          <div className={`toggle-switch ${is_active ? 'active' : ''}`} onClick={toggleState}>
            <div className="toggle-knob"></div>
          </div>
          <span>{is_active ? 'Active' : 'Inactive'}</span>
        </div>

     
        <div className="add-save-subs-btn">
          <CustomBtn text="Save Changes" onClick={handleSave} />
          <CustomBtn
            text="Reset"
            custmstyle={style}
            onClick={() =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                plan_name: '',
                original_price: '',
                discounted_price: '',
                features: '',
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditPlan;
