import React from 'react'
import AddEditPlan from '../../common/AddEditPlans/AddEditPlan'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateSubscriptionPlanChef } from '../../api';
import { useSelector } from 'react-redux';

const ChefEditSubscription = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const navigate=useNavigate();

  const {state}=useLocation();


 const handleEditSubscription = async (data) => {
  // console.log("ff",data)
    try {
      const response = await updateSubscriptionPlanChef({token,data})
        const sucessMsg = response?.message;
           if (Array.isArray(sucessMsg)) {
             sucessMsg.forEach((msg) => toast.success(msg));
           } else if (sucessMsg) {
             toast.success(sucessMsg);
           }
        navigate('/dashboard/chefs/subscription-management/manage-your-plans');
      }

    catch (error) {
            console.log("error",error)
          const errorMessage = error?.response?.data?.errors;
           if (Array.isArray(errorMessage)) {
             errorMessage.forEach((msg) => toast.error(msg));
           } else if (errorMessage) {
             toast.error(errorMessage);
           }
    }
  };


    const breadcrumbItems = [
        <Link key="home" to="/dashboard" className="All-color-breakcurm">
          Home
        </Link>,
        <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
         Chef’s 
      </Link>,
        <Link key="home" to="/dashboard/chefs/subscription-management" className="All-color-breakcurm">
        Subscription
      </Link>,
        <Link key="home" to="/dashboard/chefs/subscription-management/manage-your-plans" className="All-color-breakcurm">
        Manage Your Plans
      </Link>,
        
        <span style={{ fontWeight: "bolder" }}>&nbsp;Edit Subscription</span>,
      ];
  return (
    <div>
     <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <AddEditPlan editedData={state} onSave={handleEditSubscription}/>
    </div>
  )
}

export default ChefEditSubscription