import React, { useEffect, useState } from "react";
import "./CreateNotification.css";
import CustomCard1 from "../../common/customCard1/CustomCard1";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CarbonAvtar from "../../common/assest/carbonavatar.svg";
import AddIcon from "@mui/icons-material/Add";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchItem from "../../common/assest/searchIcon.svg";
import userimg from "../../common/assest/usrimg.png";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import { addNotificationpi, editNotificationApi, getAllUseristNotificationApi } from "../../api";
import { toast } from "react-toastify";
import alertDialog from "../../utils/alertDialog";
import InfiniteScroll from "react-infinite-scroll-component";

const CreateNotification = ({eData}) => {

  const token = useSelector((state) => state?.user?.user?.token);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("All user");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [myTimout,setMyTimeOut]=useState();
  const [notificationItems, setNotificationItems] = useState({
    title: "",
    description: "",
    publishing_date: "",
    publishing_time: "",
    send_to: "All user",
    users: [],
  });
  const [userCount,setUserCount]=useState(0)


  // useEffect(() => {
  //   if (eData) {
  //     setNotificationItems({
  //       title: eData.title,
  //       description: eData.description,
  //       publishing_date: dayjs(eData.publishing_date).format("YYYY-MM-DD"),
  //       publishing_time: eData.publishing_time,
  //       send_to: eData.send_to,
  //       users: eData.users || [],
  //     });
  //     setSelectedType(eData.send_to);
  
  //     if (eData.send_to === "Selected user" && eData.users) {
  //       setSelectedUsers(eData.users);
  //     }
  //   }
  // }, [eData]);

  useEffect(() => {
    if (eData) {
      setNotificationItems({
        title: eData.title,
        description: eData.description,
        publishing_date: dayjs(eData.publishing_date).format("YYYY-MM-DD"),
        publishing_time: eData.publishing_time,
        send_to: eData.send_to,
        users: eData.users || [],
      });
      setSelectedUsers(eData.users || []); 
      setSelectedType(eData.send_to);
    }
  }, [eData]);
  

  const resetFields = () => {
    setNotificationItems({
      title: "",
      description: "",
      publishing_date: "",
      publishing_time: "",
      send_to: "All user",
      users: [],
    });
    setSelectedUsers([]);
    setSelectedType("All user");
    setSearchQuery("");
    setCurrentPage(1);
  };
  
  const handleChangesUser = (event) => {
    const value = event.target.value;
    setSelectedType(value);
    setNotificationItems({
      ...notificationItems,
      send_to: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSearchQuery('')
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
    setNotificationItems({ ...notificationItems, [name]: value });
    }
  };

  const fetchAllUser = async (page) => {
    if (selectedType === "Selected user") {
      try {
        const response = await getAllUseristNotificationApi({ token, currentPage: page, searchQuery });
        setUserCount(response?.total||0)
        // console.log("re",response)
        if (page === 1) {
          setUserList(response?.data || []); 
          // setUserList((prevList) => [...prevList, ...(response?.data || [])]);
        } else {
          setUserList((prevList) => [...prevList, ...(response?.data || [])]);
        }
        setTotalPage(response?.pages || 1);
        setCurrentPage(page);
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    }
  };
  

  useEffect(() => {
    if (selectedType === "Selected user") {
      fetchAllUser(currentPage); 
    }
  }, [selectedType, currentPage]); 

  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchAllUser(1);
    } else {
      clearTimeout(myTimout); // Clear previous timeout if searchQuery changes
      const timeout = setTimeout(() => fetchAllUser(1), 500);
      setMyTimeOut(timeout); // Store timeout id
      return () => clearTimeout(timeout); // Cleanup timeout on component unmount
    }
  }, [searchQuery]);
  

    const handleAddRemoveUser = (userId) => {
      setSelectedUsers((prevSelected) => 
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId) 
          : [...prevSelected, userId]  
      );
    };

  // const handleAddRemoveUser = (userId) => {
  //   setSelectedUsers((prevSelected) => {
  //     const updatedSelected = prevSelected.includes(userId)
  //       ? prevSelected.filter((id) => id !== userId) // Remove if already selected
  //       : [...prevSelected, userId]; // Add if not already selected
  
  //     // Update the `notificationItems.users` state
  //     setNotificationItems((prev) => ({
  //       ...prev,
  //       users: [...new Set(updatedSelected)], // Ensure unique values
  //     }));
  
  //     return updatedSelected;
  //   });
  // };
  
  const openUserModal = () => {
    setOpen(true);
  };

  const handleSearchChange = (e) => {
    if (e.target.value.trim() !== "" || e.target.value === "") {
    setSearchQuery(e.target.value);
    }
  };

 const handleModalAdd = () => {
    if (selectedUsers.length === 0) { 
      toast.warning("Please select at least one user.");
      return; 
    }
    setNotificationItems((prev) => ({
      ...prev,
      users: [...new Set([...prev.users, ...selectedUsers])],
    }));
    setOpen(false); 
  };
  
  const handleSaveNotification = async () => {
    try {
      if (!notificationItems.title || !notificationItems.description ) {
        toast.warning("Title and Description required");
        return;
      }
      if (!notificationItems.publishing_date || !notificationItems.publishing_time || !selectedType) {
        toast.warning("Date,Time and user type required");
        return;
      }

      if (selectedType === "Selected user" && notificationItems.users.length === 0) {
        toast.warning("Please select at least one user.");
        return;
      }

      const formattedDate = dayjs(notificationItems.publishing_date).format("MM-DD-YYYY");
      const payload = {
        title: notificationItems.title,
        description: notificationItems.description,
        publishing_date: formattedDate,
        publishing_time: notificationItems.publishing_time,
        send_to: selectedType,
      };

      if (selectedType === "Selected user") {
        if (notificationItems.users.length === 0) {
          alert("Please select at least one user.");
          return;
        }
        payload.users = notificationItems.users;
      }

      const response = await addNotificationpi({ token, payload });
      toast.success(response?.message);
      resetFields();
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };


  const handleEditNotification = async () => {
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Update this Notification?'
    });
    if(!confirmed){
      return
    }
    try {
      if (!notificationItems.title || !notificationItems.description ) {
        toast.warning("Title and Description required");
        return;
      }
      if (!notificationItems.publishing_date || !notificationItems.publishing_time || !selectedType) {
        toast.warning("Date,Time and user type required");
        return;
      }

      if (selectedType === "Selected user" && notificationItems.users.length === 0) {
        toast.warning("Please select at least one user.");
        return;
      }

      const formattedDate = dayjs(notificationItems.publishing_date).format("MM-DD-YYYY");
      const payload = {
        id: eData?._id, 
        title: notificationItems.title,
        description: notificationItems.description,
        publishing_date: formattedDate,
        publishing_time: notificationItems.publishing_time,
        send_to: selectedType,
      };
  
      if (selectedType === "Selected user") {
        if (notificationItems.users.length === 0) {
          toast.warning("Please select at least one user.");
          return;
        }
        payload.users = notificationItems.users;
      }
  
      const response = await editNotificationApi({ token, payload });
      toast.success(response?.message);
      resetFields(); // Clear fields after success
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleSaveChanges = () => {
    if (eData?._id) {
      handleEditNotification();
    } else {
      handleSaveNotification();
    }
  };

  const loadMoreTickets = () =>{
    if(currentPage<totalPage){
      setCurrentPage((prevPage) => prevPage + 1);
    }
}
const hasMoreTickets = currentPage < totalPage;
  return (
    <div>
      <CustomCard1>
        <Grid container spacing={4}>
          <div className="create-push-notification">
           {eData?._id ? 'Update Push Notification':' Create New Push Notification'}
          </div>

          <Grid item xs={12} md={8}>
            <div className="create-notification">
              <Grid container spacing={2}>
                {/* Notification Title */}
                <Grid item xs={12}>
                  <Typography className="acc-management-text">
                    Notification Title
                  </Typography>
                  <input 
                    type="text"
                    style={{
                      width: "100%",
                      border: "none",
                      backgroundColor: "#F0F0F0",
                      borderRadius: "100px",
                      // resize: "none",
                      outline: "none",
                      textIndent: "20px",
                      // paddingTop: "20px",
                      height: "47px",
                      opacity:0.7,
                      fontFamily: "Rosario",
                      fontWeight:'500'
                    }}
                    placeholder="Enter here"
                    name="title"
                    value={notificationItems.title}
                    onChange={handleInputChange}
                    // sx={{
                    //   width: "100%",
                    //   "& .MuiOutlinedInput-root": {
                    //     "& fieldset": {
                    //       border: "none",
                    //     },
                    //     height: "45px",
                    //   },
                    // }}
                    // InputProps={{
                    //   style: {
                    //     borderRadius: "28px",
                    //     backgroundColor: "#F0F0F0",
                    //     fontSize: "14px",
                    //     fontFamily: "Rosario",
                    //   },
                    // }}
                  />
                </Grid>

                {/* Notification Description */}
                <Grid item xs={12}>
                  <Typography className="acc-management-text">
                    Notification Description
                  </Typography>
                  <textarea
                    placeholder="Enter your Description"
                    rows={6}
                    cols={89}
                    name="description"
                    value={notificationItems.description}
                    onChange={handleInputChange}
                    style={{
                      width: "100%",
                      border: "none",
                      backgroundColor: "#F0F0F0",
                      borderRadius: "25px",
                      resize: "none",
                      outline: "none",
                      textIndent: "20px",
                      paddingTop: "20px",
                      minHeight: "100px",
                      opacity:0.7,
                      lineHeight:'110%'
                    }}
                  ></textarea>
                </Grid>
              </Grid>

              {/* Date and Time of Publishing */}
              <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} sm={6}>
                  <Typography className="acc-management-text">
                    Date of Publishing
                  </Typography>
                  <TextField
                      type="date"
                      name="publishing_date"
                      value={notificationItems.publishing_date}
                      onChange={handleInputChange}
                      placeholder="Enter Date"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          height: "45px",
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "28px",
                          backgroundColor: "#F0F0F0",
                          fontSize: "14px",
                          fontFamily: "Rosario",
                        },
                      }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0], 
                      }}
                    />

                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className="acc-management-text">
                    Time of Publishing
                  </Typography>
                  <TextField
                    type="time"
                    name="publishing_time"
                    value={notificationItems.publishing_time}
                    onChange={handleInputChange}
                    placeholder="Enter Time"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        height: "45px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "28px",
                        backgroundColor: "#F0F0F0",
                        fontSize: "14px",
                        fontFamily: "Rosario",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Send Notification Options */}
              <Grid container spacing={2}>
                <Grid item xs={12} mt={4}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontFamily: "Rosario" }}>
                      Send Notification
                    </div>
                    <div>
                      <FormControl component="fieldset" >
                        <RadioGroup
                          value={selectedType}
                          onChange={handleChangesUser}
                          row
                        >
                          <FormControlLabel
                            value="All user"
                            control={<Radio 
                              sx={{
                                '&.Mui-checked': {
                                  color: '#EA1D26',  
                                },
                              }}
                            />}
                            label="To all User"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontFamily: 'Rosario', 
                                 
                              }
                            }}
                          />
                          <FormControlLabel
                            value="Selected user"
                            control={<Radio 
                              sx={{
                                '&.Mui-checked': {
                                  color: '#EA1D26',  
                                },
                              }}
                            />}
                            label="To Selected User"
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                fontFamily: 'Rosario', 
                                 
                              }
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  {/* Add Selected Users */}
                  {/* {selectedType === "Selected user" && (
                   <div style={{display:'flex',justifyContent:'space-between'}}>
                          <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'5px',flexWrap:'wrap'}}>
                      
                          <AvatarGroup max={4} disableTooltip total={4}>
                              {userList.length > 0 &&
                                userList.map((user, index) => (
                                  <Avatar
                                    key={index}
                                    alt="user-img"
                                    src={user?.profile_picture || null} 
                                  />
                                ))}
                            </AvatarGroup>

                      {userCount > 4 && (
                        <div style={{ color: '#000', opacity: '0.5', fontFamily: 'Rosario' }}>
                          {`${userCount - 4}+ Members`}
                        </div>
                      )}


                          </div>
                   <div> <Button
                      onClick={() => openUserModal()}
                      sx={{
                        mt: 2,
                        background: "#EA1D26",
                        color: "white",
                        borderRadius:"18px",
                        fontSize:'11px',
                        textTransform:'none'
                      }}
                    >
                      <AddIcon sx={{fontSize:'11px'}}/> Add
                    </Button></div>
                   </div>
                  )} */}


                    {selectedType === "Selected user" && (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                          
                          {/* Only render AvatarGroup if userList has at least one user */}
                          {userList.length > 0 && (
                            <AvatarGroup max={4} disableTooltip total={userList.length}>
                              {userList.map((user, index) => (
                                <Avatar key={index} alt="user-img" src={user?.profile_picture || undefined} />
                              ))}
                            </AvatarGroup>
                          )}

                          {/* Show "+ Members" count only if more than 4 users exist */}
                          {userList.length > 4 && (
                            <div style={{ color: '#000', opacity: '0.5', fontFamily: 'Rosario' }}>
                              {`${userList.length - 4}+ Members`}
                            </div>
                          )}

                        </div>

                        <div>
                          <Button
                            onClick={() => openUserModal()}
                            sx={{
                              mt: 2,
                              background: "#EA1D26",
                              color: "white",
                              borderRadius: "18px",
                              fontSize: '11px',
                              textTransform: 'none'
                            }}
                          >
                            <AddIcon sx={{ fontSize: '11px' }} /> Add
                          </Button>
                        </div>
                      </div>
                    )}


                  {/* Save Button */}
                  <div style={{ marginTop: "20px" }}>
                    <CustomBtn1 text="Save" onClick={handleSaveChanges} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CustomCard1>


      <CustomModal open={open} handleClose={handleClose}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#6E6E6E",
            padding: "5px",
          }}
        >
          <div
            style={{ color: "#120B1C", fontFamily: "Rosario", fontWeight: 700 }}
          >
            Select User
          </div>
          <div
            style={{
              backgroundColor: "#EBEBEB",
              height: "26px",
              width: "26px",
              borderRadius: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <ClearIcon sx={{ color: "black", fontSize: "18px" }} />
          </div>
        </div>

        <TextField
          placeholder="Search by name"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            marginTop: "10px",
            borderRadius: "33px",
            background: "#F6F5F6",
            "& .MuiOutlinedInput-root": {
              borderRadius: "33px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchItem} alt="Search" />
              </InputAdornment>
            ),
          }}
        />

       <Box id="scrollableDiv" sx={{height:'300px',overflow:'scroll',marginTop:'15px',marginBottom:'15px',
        '&::-webkit-scrollbar': {
    display: 'none',
  },
       }}>
        
        <InfiniteScroll
                dataLength={userList.length}
                next={loadMoreTickets}
                hasMore={hasMoreTickets}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>You have seen it all</b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
                key={userList.length}
              >
       {userList.length > 0 ? (
          userList.map((item) => (
            <div className="notification-user-container" key={item?._id}>
              <div className="user-name-image">
                <div className="notification-add-remove">
                  <img
                    src={item?.profile_picture || CarbonAvtar}
                    alt="Profile"
                    style={{
                      width: "55px",
                      height: "55px",
                      borderRadius: "12px",
                    }}
                  />
                  <div>{item?.full_name || "N/A"}</div>
                </div>
        
                <div
                    onClick={() => handleAddRemoveUser(item?._id)}
                    style={{
                      width: selectedUsers.includes(item?._id) ? "auto" : "30px", 
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: selectedUsers.includes(item?._id) ? "red" : "white", 
                      cursor: "pointer",
                      padding: selectedUsers.includes(item?._id) ? "0 10px" : "0",
                    }}
                  >
                    {selectedUsers.includes(item?._id) ? (
                      // Display "Remove" text without any background
                      <span style={{ backgroundColor: "none", color: "red" }}>Remove</span>
                    ) : (
                      <AddIcon sx={{ color: "white",backgroundColor:'#AAA',height: "28px",width:'28px',  borderRadius: "10px", }} />
                    )}
                 </div>

                </div>
           
            </div>
            
          ))
          
        ) : (
          <div style={{textAlign:'center',fontFamily:'Rosario',marginTop:'15px'}}>No User Found</div>
        )}
      </InfiniteScroll>
       </Box>
        <CustomBtn1 text="ADD" onClick={handleModalAdd} />
      </CustomModal>
    </div>
  );
};


export default CreateNotification;
