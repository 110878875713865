import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import CustomBtn1 from '../../common/customBtn1/CustomBtn1';
import { AddMissionChef, getMissionChef } from '../../api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import alertDialog from '../../utils/alertDialog';
import Loader from '../../common/loader/Loader';

const ChefMission = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [missions, setMissions] = useState(Array(5).fill("")); 
  const [loading ,setLoading]=useState(false)

  const handleMissionChange = (index, value) => {
    if (value.trim() !== "" || value === "") {
      const newMissions = [...missions];
      newMissions[index] = value;
      setMissions(newMissions);
    }
  };

  const handleGetMission = async () => {
    setLoading(true)
    try {
   
      const response = await getMissionChef({ token });
        setLoading(false)
      // console.log("API Response:", response);
      if (response && response[0] && response[0].mission) {
        const missionData = response[0].mission;
        const updatedMissions = [...missionData, ...Array(5 - missionData.length).fill("")];
        setMissions(updatedMissions);
      }
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  useEffect(() => {
    handleGetMission();
  }, []);

  const onSave = async () => {
    const payload = { mission: missions };
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Update Mission?'
    });
    if(!confirmed){
      return
    }
    try {
      const res = await AddMissionChef({ token, payload });
      toast.success("Mission Updated Successfully!");
    } catch (error) {
      const errorMessage = error?.response?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="chefs" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link key="content" to="/dashboard/chefs/content-management" className="All-color-breakcurm">
      Content
    </Link>,
    <span key="mission" style={{ fontWeight: "bolder" }}>&nbsp;Mission</span>,
  ];

  return (
    <div>
         {loading && <Loader/>}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
      
      {missions.map((mission, index) => (
        <div key={index} style={{ padding: '5px' }}>
          <Typography className="acc-management-text" mt={2}>Mission {index + 1}</Typography>
          <input
            type="text"
            placeholder="Enter here"
            value={mission}
            onChange={(e) => handleMissionChange(index, e.target.value)}
            className="chef-common-input"
          />
        </div>
      ))}
      
      <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
        <CustomBtn1 text="Save Changes" onClick={onSave} style={{ maxWidth: "480px" }} />
      </div>
    </div>
  );
};

export default ChefMission;
