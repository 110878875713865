import { Axios } from "./axios.interceptor";

// ************************************************ login Api*****************************************************
export const loginApi = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "/admin/login",
                {
                    email: user.email,
                    password: user.password,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};



// forget section 

export const verifyEmailApi = ({email}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/user/forgot-password",
                {
                    email
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const verifyOTPApi = ({email,otp}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/user/verify-otp",
                {
                    email,
                    otp
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


export const changePasswordApi = ({otpData,confirmNewPass}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/user/reset-password",
                {
                    email:otpData.reference,
                    otp:otpData.otp,
                    new_password:confirmNewPass
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


// *************************************Graph Data***********************************************************************
export const getGraphData=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/users-enrolled`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


// ************************************************ Admin Management *****************************************************
export const getAllAdminApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/get-all?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addAdminApi=({token,admin})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                "/admin/add",
                {   full_name:admin.fullName,
                    email: admin.email,
                    password: admin.password,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const updateAdminApi = ({data,token}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/update",
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


export const deleteApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/delete`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}




// ************************************************ Chefs FAQ  *****************************************************

export const getAllFaqApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/chef/faq`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addFaqpi=({token,faqData})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                "/admin/chef/faq",
                {   title:faqData.question,
                    content: faqData.answer,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const editFaqApi = ({faqData,token}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/chef/faq",
                 {
                    id:faqData?.id,
                    title:faqData?.question,
                    content:faqData?.answer
                 }  ,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const deleteFaqApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/chef/faq`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

// ************************************************ Chef Sections  *****************************************************


export const chefDetailsApi=({token,currentPage,searchQuery,filter})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/all-chef?page=${currentPage}&&search=${searchQuery}&&filter=${filter}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const particularChefDetailApi=({token,chefId})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/chef-details/${chefId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const deletChefApi= async ({ id, token }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(`/admin/delete-chef`, {
          data: { chefId: id },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        resolve(response);
      } catch (error) {
        reject(error);
        console.log(`Error deleting user: ${error.message}`);
      }
    });
  };

// suspend , resume same api
  export const suspendChefApi = ({ token, id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/admin/banned-resume-chef`,
          { chefId:id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching payment data:", error);
        reject(error);
      }
    });
  };


//   reviews chefss

 export const getChefsReviewApi=({token,id,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                `/chef/event/chef-all-reviews/${id}?page=${currentPage}&&search=${searchQuery}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}
    export const deleteChefReviewApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/chef/event/delete-review`,{
                    data: {review_id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

// menu item and guest item

export const getChefsGListApi=({token,eid})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                `/chef/event/reservation/${eid}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getChefsMenuItemApi=({token,eid,cid})=>{
    // console.log("cid",cid)
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/chef/event/menuitems/${eid}/${cid}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}





export const getChefsEventsApi=({token,id,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/chef/events/${id}?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

//   Tickets Api Chefs

export const getAllChefsTicketApi=({token,currentPage,status,limit})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/chef/ticket/all?page=${currentPage}&&status=${status}&&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const singleTicketDataApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/ticket?id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const chefChatMessageApi = ({ token, messagePayload }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/chef/support-chat-admin`,
          messagePayload,

                  {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };


  export const chefChatDataApi = ({ token, ticket }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/chef/support-chat/messages`,
          {ticket},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };


  
  export const chefChatTicketCloseApi = ({ token, id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/chef/close-ticket`,
          {ticket_id:id},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error closing ticket data:", error);
        reject(error);
      }
    });
  };



  export const getSubscriptionPlanChef=({token,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/chef/manage-subscription-plan?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const addSubscriptionPlanChef = ({ token, data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/admin/chef/manage-subscription-plan`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };

  export const updateSubscriptionPlanChef = ({ token, data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/admin/chef/manage-subscription-plan`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };




  export const deleteSubscriptionPlanChef=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/chef/manage-subscription-plan`,{
                    data: {id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



  export const updatePrivacyChef = ({ token, data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.patch(
          `/admin/privacy-policy`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };



  
  export const getPrivacyChef=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/privacy-policy`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getMissionChef=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/chef/mission`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const AddMissionChef = ({ token, payload }) => {
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.patch(
          `/admin/chef/mission`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };




  export const getRefundChef=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/refund-and-cancellation`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const AddRefundChef = ({ token, data }) => {
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.patch(
          `/admin/refund-and-cancellation`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };



  export const getTermsandConditionChef=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/terms-and-condition`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const AddTermsandConditionChef = ({ token, data }) => {
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.patch(
          `/admin/terms-and-condition`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };
// ************************************************ Guests Sections  *****************************************************


export const guestsListApi=({token,currentPage,searchQuery,filter})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/all-user?page=${currentPage}&&search=${searchQuery}&&filter=${filter}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const particularGuestsApi=({token,userId})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/user-details/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

// faq guest
export const getAllGuestFaqApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/user/faq`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addGuestFaqApi=({token,faqData})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                "/admin/user/faq",
                {   title:faqData.question,
                    content: faqData.answer,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const editGuestFaqApi = ({faqData,token}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/user/faq",
                 {
                    id:faqData?.id,
                    title:faqData?.question,
                    content:faqData?.answer
                 }  ,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const deleteGuestFaqApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/user/faq`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getGuestReviewApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                `/chef/event/user-all-review/${id}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


// events guests


export const getGuestsEventsApi=({token,id,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/chef/events/user-attended/${id}?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getGuestsEventsDetailsApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/chef/event-details/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const getAllGuestsTicketApi=({token,currentPage,status,limit})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/user/ticket/all?page=${currentPage}&&status=${status}&&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const guestsDataApi = ({ token, ticket }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/user/support-chat/messages`,
          {ticket},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };


export const guestsChatMessageApi = ({ token, messagePayload }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/user/support-chat-user`,
          messagePayload,

                  {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };


  
  export const GuestsChatTicketCloseApi = ({ token, id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/user/closed-ticket`,
          {ticket_id:id},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error closing ticket data:", error);
        reject(error);
      }
    });
  };



// ************************************************ Notification Sections  *****************************************************

export const getNotificationList=({token,searchQuery,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/scheduled-notification?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


export const getAllUseristNotificationApi=({token,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/users?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addNotificationpi = ({ token, payload }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          "/admin/scheduled-notification",
          payload, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        resolve(response);
      } catch (error) {
        console.log("Error:", error);
        reject(error.response);
      }
    });
  };
  

export const editNotificationApi = ({payload,token}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/scheduled-notification",payload ,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const deleteNotificationApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/scheduled-notification`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getSubscriptionPlanGuests=({token,currentPage,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/user/manage-subscription-plan?page=${currentPage}&&search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const addSubscriptionPlanGuest = ({ token, data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/admin/user/manage-subscription-plan`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error);
      }
    });
  };

  export const updateSubscriptionPlanGuests = ({ token, data }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/admin/user/manage-subscription-plan`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };




  export const deleteSubscriptionPlanGuests=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/user/manage-subscription-plan`,{
                    data: {id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


// ************************************************ Rules and Food Sepciality Sections *****************************************


export const getAllRuleApi=({token,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/rules-management?search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const addNewRuleApi = ({ token, inputTxt }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/admin/rules-management`,
          {rules:inputTxt},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error.response);
      }
    });
  };

  export const updateRuleApi = ({ token, updatedRule }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/admin/rules-management`,
          {
            id:updatedRule?._id,
            rules:updatedRule?.rules
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error.response);
      }
    });
  };




  export const deleteRuleApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/rules-management`,{
                    data: {id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



// food 






export const getFoodApi=({token,searchQuery})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/food-speciality?search=${searchQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const addNewFoodApi = ({ token, payload }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          `/admin/food-speciality`,
          {name:payload.name,image:payload.image},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error fetching  data:", error);
        reject(error.response);
      }
    });
  };

  export const updateFoodApi = ({ token, updateFoodSpecialty }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          `/admin/food-speciality`,
          {
            id:updateFoodSpecialty?._id,
            name:updateFoodSpecialty?.name,
            image:updateFoodSpecialty?.image
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error", error);
        reject(error.response);
      }
    });
  };




  export const deleteFoodApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/food-speciality`,{
                    data: {id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}



export const getMissionUser=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/user/mission`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const AddMissionUser = ({ token, payload }) => {
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.patch(
          `/admin/user/mission`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        resolve(response);
      } catch (error) {
        console.error("Error  data:", error);
        reject(error);
      }
    });
  };

