import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AddEditPlan from '../../common/AddEditPlans/AddEditPlan';
import { useSelector } from 'react-redux';
import { addSubscriptionPlanGuest } from '../../api';
import { toast } from 'react-toastify';

const GuestAddSub = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const navigate = useNavigate();

  const handleAddSubscription = async (data) => {
    try {
      const response = await addSubscriptionPlanGuest({token,data})
        const sucessMsg = response?.message;
           if (Array.isArray(sucessMsg)) {
             sucessMsg.forEach((msg) => toast.success(msg));
           } else if (sucessMsg) {
             toast.success(sucessMsg);
           }
        navigate('/dashboard/guests/subscription-management/manage-your-plans');
      }

    catch (error) {
            console.log("error",error)
          const errorMessage = error?.response?.data?.errors;
           if (Array.isArray(errorMessage)) {
             errorMessage.forEach((msg) => toast.error(msg));
           } else if (errorMessage) {
             toast.error(errorMessage);
           }
    }
  };


      const breadcrumbItems = [
            <Link key="home" to="/dashboard" className="All-color-breakcurm">
              Home
            </Link>,
            <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
             Guest's 
          </Link>,
            <Link key="home" to="/dashboard/guests/subscription-management" className="All-color-breakcurm">
            Subscription
          </Link>,
            <Link key="home" to="/dashboard/guests/subscription-management/manage-your-plans" className="All-color-breakcurm">
            Manage Your Plans
          </Link>,
            
            <span style={{ fontWeight: "bolder" }}>&nbsp;Add Subscription</span>,
          ];

  return (
    <div>
     <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <AddEditPlan onSave={handleAddSubscription}/>
    </div>
  )
}

export default GuestAddSub