import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import ChefLogo from '../assest/cheflogo.png'
import SideBarImg from '../assest/Side Navigation.png'
import HomeLogo from '../assest/home.svg'
import UserShapeIcon from '../assest/combo_shape.svg'
import BookShapeIcon from '../assest/Combo_shape1.svg'
import UserShapeIconFilled from '../assest/Subtracts.svg'
import BellIconS from '../assest/line-md_bell-filled.svg';
import FoodIcon from '../assest/ep_menu.svg'
import RulesIcon from '../assest/mingcute_pencil-ruler-fill.svg'
import "./Main.css";

const Sidebar = ({ isSidebarOpen, toggleSidebar, isMobileView }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const navigate = useNavigate();
  const location = useLocation(); 

  // useEffect(() => {
  //   const currentPath = location.pathname;

  //   if (currentPath.includes("/account-management")) {
  //     setActiveTab("account");
  //   } else if (currentPath.includes("/admin-management")) {
  //     setActiveTab("admin");
  //   } else if (currentPath.includes("/settings")) {
  //     setActiveTab("settings")
  //    } else if (currentPath.includes("/content-management")) {
  //       setActiveTab("content-management");
  //   } else {
  //     setActiveTab("dashboard");
  //   }
  // }, [location]); 

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.startsWith("/account-management")) {
      setActiveTab("account");
    } 
    else if (currentPath.startsWith("/admin-management")) {
      setActiveTab("admin");
      
    }
    else if (currentPath.startsWith("/rules-management")) {
      setActiveTab("rules");
    }
    else if (currentPath.startsWith("/food-specialty")) {
      setActiveTab("food");
    }
    
    else if (currentPath.startsWith("/push-notification")) {
      setActiveTab("settings");
    } else if (currentPath.startsWith("/dashboard/content-management")) {
      setActiveTab("content-management");
    } else {
      setActiveTab("dashboard");
    }
  }, [location]);

  const handleRoutes = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "dashboard":
        navigate("/dashboard");
        break;
        case "food":
          navigate("/food-specialty ");
          break;
          case "rules":
            navigate("/rules-management");
            break;
      case "account":
        navigate("/account-management");
        break;
      case "admin":
        navigate("/admin-management");
        break;
        case "content":
        navigate("/dashboard/content-management");
        break;
      case "settings":
        navigate("/push-notification");
        break;
      default:
        break;
    }
  };

  const listItems = [
    { 
      key: "dashboard", 
      icon: HomeLogo, 
      text: "Dashboard" 
    },
    { 
      key: "food", 
      icon: FoodIcon, 
      text: "Food Specialty " 
    },
    { 
      key: "rules", 
      icon: RulesIcon, 
      text: "Rules Management" 
    },
    { 
      key: "account", 
      icon: UserShapeIcon, 
      text: "Account Management" 
    },
    { 
      key: "admin", 
      icon: BookShapeIcon, 
      text: "Admin Management" 
    },
    { 
      key: "settings", 
      icon: BellIconS, 
      text: "Manage Notification" 
    },
  ];
  
  const commonStyles = {
    fontFamily: "Rosario",
    maxWidth: "230px",
    marginTop: "10px",
    height: "60px",
    cursor: "pointer",
    borderRadius: "18px",
    opacity: 0.7,
    fontSize:'2px !important',
    color: "#FFF",
    "& .css-rizt0-MuiTypography-root": {
      fontFamily: "Rosario !important",
    },
  };

  return (
    <Drawer
      sx={{
        borderRadius: "0px 24px 24px 0px !important",
        "& .MuiPaper-root": {
          borderRadius: "0px 24px 24px 0px",
          border: "none",
          height: "100vh",
          backgroundColor: "none",
          fontFamily:'Rosario'
        },
      }}
      anchor="left"
      open={isSidebarOpen || !isMobileView}
      onClose={toggleSidebar}
      variant={isMobileView ? "temporary" : "persistent"}
    >
      <div
        style={{
          width: "244px",
          height: "100vh",
          borderRadius: "0px 24px 24px 0px",
        //   backgroundColor: "#9A031E",
        backgroundImage: `url(${SideBarImg})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
          paddingLeft: "10px",
        }}
      >
        <List sx={{ marginTop: "35px" ,fontFamily:'Rosario'}}>
          <img
            src={ChefLogo}
            style={{ width: "140px" ,marginLeft:'40px',}}
            alt="Logo"
          />

          <div style={{marginLeft:'57px',fontSize:"22px",fontFamily:"Rosario",color:'#FFF',marginTop:"5px"}}>Chef’s Table</div>

          {listItems.map(({ key, icon, text }, index) => (
      <ListItem
        key={key}
        sx={{
          ...commonStyles,
          marginTop: index === 0 ? "30px" : "10px",
          opacity: activeTab === key ? "1" : "0.7",
          color:activeTab===key?"#fff":"#fff",
          backgroundColor: activeTab === key ? "rgba(255, 255, 255, 0.3)" : "transparent",
         
        }}
        onClick={() => handleRoutes(key)}
      >
        <ListItemIcon sx={{ minWidth: "35px" }}>
          <img src={icon} style={{ color: "#B0B0B0" }} alt={`${text} Icon`} />
        </ListItemIcon>
        <ListItemText  >
         <div style={{fontSize:'16.5px',fontFamily:"Rosario"}}>
         {text}
         </div>
        </ListItemText>
      </ListItem>
    ))}
    
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;



 {/*Content Management*/}

            {/* <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "10px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
           
              opacity: activeTab === "content-management" ? "1" : "0.6",
              color: activeTab === "content-management" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "content-management" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("content")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={UserShapeIconFilled} style={{ color: "#B0B0B0" }} alt="Settings Icon" />
            </ListItemIcon>
            <ListItemText primary="Content Management" sx={{ fontFamily: "Rosario" }} />
          </ListItem> */}
