import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Mission.css";
import { Typography } from "@mui/material";
import CustomBtn1 from "../customBtn1/CustomBtn1";

const QuillEditor = ({ title, content, setTitle, setContent, onSave }) => {
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ align: [] }],
      [{ color: [] }],
      [{ font: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header", "bold", "italic", "underline", "strike", "blockquote",
    "list", "bullet", "link", "image", "align", "color", "font", "size",
  ];

  return (
    <div className="outer-wrapper">
      <Typography className="acc-management-text">Title</Typography>
      <input
        type="text"
        placeholder="Enter here"
        value={title}
        onChange={(e) =>{
      
          if (e.target.value.trim() !== "" || e.target.value === "") {
            setTitle(e.target.value)}
        }  
      }
        className="chef-common-input"
      />
      <br /> <br />

      <Typography className="acc-management-text">Content</Typography>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={content}
        onChange={setContent}
        style={{
          minHeight: "80%",
          padding: "0px",
          borderRadius: "48px",
          width: "100%",
        }}
      />
      <br /> <br />

      <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}>
        <CustomBtn1 text="Save Changes" onClick={onSave} style={{ maxWidth: "480px" }} />
      </div>
    </div>
  );
};

export default QuillEditor;
