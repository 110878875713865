import { Button, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./chefSubscription.css";
import TableCard from "../../common/tableCard/TableCard";
import SearchBar from "../../common/searchbar/SearchBar";
import TableComp from "../../common/tablecomp/TableComp";
import SubscriptionPlanRow from "../../common/tablerow/SubscriptionPlanRow";
import { deleteSubscriptionPlanChef, getSubscriptionPlanChef } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import alertDialog from "../../utils/alertDialog";

const ChefManagePlan = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const navigate = useNavigate();

  const [PlanData, setPlanData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);
  const [myTimout, setMyTimeOut] = useState();
  const [change, setChange] = useState(false);
  const [resetting, setResetting] = useState(false);

  const fetchManageData = async () => {
    try {
      if (!resetting) setLoading(true);
      const response = await getSubscriptionPlanChef({
        token,
        searchQuery,
        currentPage,
      });
      // console.log("pl",response)
      setPlanData(response?.data);
      setCurrentPage(response?.page);
      setTotalPage(response?.pages);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("err", error);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    fetchManageData();
  }, [currentPage,change]);

  useEffect(() => {
    if (searchQuery.trim() == "") {
      fetchManageData();
    } else {
      clearTimeout(myTimout);
      setMyTimeOut(setTimeout(fetchManageData, 500));
    }
  }, [searchQuery]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearchChange = (newValue) => {
    setSearchQuery(newValue);
  };
  const resetPage = () => {
    setResetting(true);
    setCurrentPage(1);
  };

  const titles = [
    { title: "Sr No" },
    { title: "Subscription Plans" },
    { title: "Amount" },
    { title: "Status" },
  ];

 

  const handleDelete = async (id) => {
    const confirmed = await alertDialog({
      title: "Confirm Action",
      description: "Are you sure you want to Delete this Subscription?",
    });
    if (!confirmed) {
      return;
    }
    try {
      const response = await deleteSubscriptionPlanChef({ token, id });
      setChange(!change);
      setLoading(false);
      const sucessMsg = response?.message;
      if (Array.isArray(sucessMsg)) {
        sucessMsg.forEach((msg) => toast.success(msg));
      } else if (sucessMsg) {
        toast.success(sucessMsg);
      }
    } catch (error) {
      setLoading(false);
      console.log("err", error);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/subscription-management"
      className="All-color-breakcurm"
    >
      Subscription
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Manage Your Plans</span>,
  ];
  
  return (
    <div>
      {loading && <Loader />}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="manage-pln">
        <Button
          onClick={() =>
            navigate(
              "/dashboard/chefs/subscription-management/manage-your-plans/add-subscriptions"
            )
          }
          variant="contained"
          className="create-admin-btn"
          startIcon={
            <AddCircleOutlineOutlinedIcon className="create-admin-icon" />
          }
        >
          Add Subscription
        </Button>
      </div>

      <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          <div>Subscription Plans</div>
          <div>
            <SearchBar
              onSearchChange={handleSearchChange}
              searchValue={searchQuery}
              placeholder="Search by Plan"
              resetPage={resetPage}
            />
          </div>
        </div>

        <TableComp
          title={titles}
          emptyEColumnCount={1}
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={handlePageChange}
          loading={loading}
        >
          {PlanData.length > 0 ? (
            PlanData.map((item, idx) => (
              <SubscriptionPlanRow
                row={item}
                edit={() =>
                  navigate(
                    "/dashboard/chefs/subscription-management/manage-your-plans/edit-subscriptions",
                    { state: item }
                  )
                }
                view={() =>
                  navigate(
                    "/dashboard/chefs/subscription-management/manage-your-plans/view-subscriptions",
                    {
                      state: item,
                    }
                  )
                }
                key={item?._id}
                deletePlan={() => handleDelete(item?._id)}
                idx={idx}
              />
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={titles.length}
                align="center"
                sx={{ fontFamily: "Rosario", fontWeight: "600" }}
              >
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableComp>
      </TableCard>
    </div>
  );
};

export default ChefManagePlan;
