import React, { useEffect, useState } from "react";
import RulesRow from "../../common/tablerow/RulesRow";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import { Button, TableCell, TableRow } from "@mui/material";
import TableComp from "../../common/tablecomp/TableComp";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import RulesModal from "../../common/modal/RulesModal";
import { TextFields } from "@mui/icons-material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import { useSelector } from "react-redux";
import { addNewRuleApi, deleteRuleApi, getAllRuleApi, updateRuleApi } from "../../api";
import { toast } from "react-toastify";
import alertDialog from "../../utils/alertDialog";
import Loader from "../../common/loader/Loader";

const RulesManagement = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [searchQuery, setSearchQuery] = useState("");
  const [resetting, setResetting] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [RulesData, setRulesData] = useState([]);
  const [change, setChange] = useState(false);
  const [myTimout, setMyTimout] = useState();
  const [inputTxt, setInputTxt] = useState("");
  const [editRule, setEditRule] = useState(null);


  const handleAllRule = async () => {
    try {
      if(!resetting) setLoading(true);
      const response = await getAllRuleApi({ token, searchQuery });
      setRulesData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    handleAllRule();
  }, [change]);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      handleAllRule();
    } else {
      if (myTimout) clearTimeout(myTimout);
      setMyTimout(setTimeout(handleAllRule, 500));
    }
  }, [searchQuery]);


  const handleCreateNewRule = async () => {
    if(!inputTxt){
      toast.warning("Please fill the rules")
      return 
    }
    try {
      const response = await addNewRuleApi({ token, inputTxt });
      toast.success("New Rule Added successfully");
      setChange(!change);
      setOpen(false);
      setInputTxt("");
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  
  const handleUpdateRule = async () => {
    if (!editRule) return; 
    try {
      const updatedRule = { ...editRule, rules: inputTxt };
      // console.log("updte",editRule)
      const response = await updateRuleApi({ token,  updatedRule });
      toast.success("Rule Updated successfully");
      setChange(!change);
      setOpen(false); 
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };


  const handleDeleteRule = async (id) => {
    const confirmed = await alertDialog({
      title: "Confirm Action",
      description: "Are you sure you want to Delete this Rule?",
    });
    if (!confirmed) {
      return;
    }
    try {
      const response = await deleteRuleApi({ token, id });
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  // Open modal for creating new rule
  const handleCreateRule = () => {
    setEditRule(null); 
    setInputTxt(""); 
    setOpen(true);
  };

  // Open modal for editing an existing rule
  const openEditModal = (rule) => {
    // console.log("ff",rule)
    setEditRule(rule);
    setInputTxt(rule?.rules || ""); // Pre-fill the input with the existing rule text
    setOpen(true);
  };

  
  const handleClose = () => {
    setOpen(false);
  };
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Rules Management</span>,
  ];

  const handleSearchChange = (newValue) => {
    setSearchQuery(newValue);
  };

  const style={
    width: "100%",
    maxWidth: "300px",
  }
  const resetPage = () => {
    setResetting(true);
    // setCurrentPage(1);
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="whole-card-wrapper-notification">
        <TableCard>
          <div className="push-notification-txt">Rule Management</div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <div>
              {/* <SearchBar
                style={{ width: "285px" }}
                placeholder="Search by rule"
                onSearchChange={handleSearchChange}
                resetPage={() => setResetting(true)}
              /> */}
              <SearchBar onSearchChange={handleSearchChange} searchValue={searchQuery}
            placeholder="Search by rule" style={style} resetPage={resetPage}
            />
            </div>

            <div>
              <Button
                onClick={handleCreateRule}
                sx={{ width: "200px" }}
                variant="contained"
                className="create-admin-btn"
              >
                Create New Rule
              </Button>
            </div>
          </div>

          <TableComp title={[{ title: "Sr No" }, { title: "Rules" }, { title: "Action" }]}>
            {RulesData.length > 0 ? (
              RulesData.map((item, idx) => (
                <RulesRow
                  key={item?._id}
                  index={idx}
                  row={item}
                  deleteRule={() => handleDeleteRule(item?._id)}
                  editRule={() => openEditModal(item)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={3}
                  align="center"
                  sx={{ fontFamily: "Rosario", fontWeight: "600" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableComp>
        </TableCard>
      </div>

      <RulesModal open={open} handleClose={handleClose}>
        <div className="create-modal-heading">
          <div>{editRule ? "Edit Rule" : "Create New Rule"}</div>
          <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <textarea
            placeholder="Enter here"
            value={inputTxt}
            onChange={(e) =>
              { 
                if (e.target.value.trim() !== "" || e.target.value === "") {
                setInputTxt(e.target.value)}
              }
            } 
            rows={3}
            cols={89}
            style={{
              width: "90%",
              border: "none",
              backgroundColor: "#F0F0F0",
              borderRadius: "25px",
              resize: "none",
              outline: "none",
              textIndent: "20px",
              paddingTop: "20px",
              fontWeight: "400",
              color: "#120B1C",
              fontFamily: "Rosario",
            }}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <CustomBtn1
              text={editRule ? "Update" : "Add"}
              onClick={editRule ? handleUpdateRule : handleCreateNewRule}
            />
          </div>
        </div>
      </RulesModal>
    </div>
  );
};

export default RulesManagement;

