import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QuillEditor from "../../common/ReactQuil/Mission";

import { useSelector } from "react-redux";
import {  getPrivacyChef, updatePrivacyChef } from "../../api";
import { toast } from "react-toastify";
import alertDialog from "../../utils/alertDialog";
import Loader from "../../common/loader/Loader";

const ChefPrivacy = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading ,setLoading]=useState(false)

  

  const handleGetPrivacy = async () => {
    setLoading(true)
    try {
      const response = await getPrivacyChef({ token });
      setLoading(false)
      if (Array.isArray(response) && response.length > 0) {
        setTitle(response[0]?.title || "");
        setContent(response[0]?.content || "");
        setLoading(false)
      }
      //  else {
      //   setLoading(false)
      //   toast.error("No privacy policy data found.");
      // }
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };
  

  useEffect(() => {
   handleGetPrivacy()
  }, []);

  const handleSave = async () => {
    const data = { title, content };
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Update Privacy Policy?'
    });
    if(!confirmed){
      return
    }
    try {

        const res = await updatePrivacyChef({ token, data });
        toast.success("Privacy Policy updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      setLoading(false)
      const errorMessage = error?.response?.data?.errors;
      
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg)); 
      } else if (errorMessage) {
        toast.error(errorMessage); 
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="chefs" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link key="content" to="/dashboard/chefs/content-management" className="All-color-breakcurm">
      Content
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Privacy Policy</span>,
  ];

  return (
    <div>
      {loading && <Loader/>}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
      <QuillEditor title={title} content={content} setTitle={setTitle} setContent={setContent} onSave={handleSave} />
    </div>
  );
};

export default ChefPrivacy;
