import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "../assest/editicon.svg";
import DeletIcon from "../assest/deleteicon.svg";

const RulesRow = ({row,deleteRule,editRule,index}) => {
 

  return (

        <TableRow>
          <TableCell>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
                textWrap:'nowrap'
              }}
            >
              <div
                style={{
                  fontFamily: "Rosario",

                  fontWeight: 500,
                  fontSize: "17px",
                }}
              >
                {index+1||"N/A"}
              </div>
            </Box>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontSize: "16px",
            }}
          >
            <div>{row?.rules ||"N/A"}</div>
          </TableCell>

       

          <TableCell
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            
              // flexWrap:'wrap',
              gap:'20px'
            }}
          >
            <div
            onClick={editRule}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#595555",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <img src={EditIcon} />
            </div>
            <div 
            onClick={deleteRule}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#EA1D26",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <img src={DeletIcon} />
            </div>
          </TableCell>
        </TableRow>
      

  );
};

export default RulesRow;
